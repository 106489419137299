/*
 * @Author: yangj
 * @Date: 2020-03-10 11:39:57
 * @LastEditors: yangj
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import "@shopify/polaris/styles.css";

const isDev = (process.env.NODE_ENV === 'development');
const urlParams = new URLSearchParams(window.location.search);
const authCode = urlParams.get("code");
if(isDev) {
  ReactDOM.render(<AppProvider i18n={enTranslations}><App /></AppProvider>, document.getElementById('root'));
} else if(authCode && authCode !== "") {
  ReactDOM.render(<AppProvider i18n={enTranslations}><App /></AppProvider>, document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
