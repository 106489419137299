/*
 * @Author: yangj
 * @Date: 2020-02-21 10:55:21
 */
import React, { Component } from "react";
import classNames from 'classnames';
import styles from './style.module.scss';
import { Supload } from '../../index';
import { save_settings } from '../../../api/api';
import { Switch } from 'antd';
import { Layout, Card, Select, RangeSlider, Link, List, TextStyle } from '@shopify/polaris';
class Index extends Component {
    constructor() {
        super();
        this.state = {
            sliderMax: 100,
            rangeValue: 0,
            fromData: { storeLogo: '', checkoutLanguage: '', checkoutChannelRatio: 0,promotion_is_active:true }
        };
    }
    componentDidMount() {
        this.initData()
    }
    initData = () => {
        let { datas } = this.props;
        const { fromData, sliderMax = 100 } = this.state;
        const { checkoutChannelRatio = 0, storeLogo, checkoutLanguage,promotion_is_active  } = datas;
        let rangeValue = sliderMax - checkoutChannelRatio;
        fromData.storeLogo = storeLogo;
        fromData.checkoutLanguage = checkoutLanguage;
        fromData.checkoutChannelRatio = checkoutChannelRatio;
        fromData.promotion_is_active = promotion_is_active;
        this.setState({ fromData, rangeValue });
    }
    componentWillUnmount() {
    }
    handleDrop = (files) => {
        this.setState({ files })
    }
    handleChange = (type, value) => {
        let { fromData } = this.state;
        fromData[type] = value;
        this.setState({ fromData })
    }
    imgcallback = (storeLogo) => {
        let { fromData } = this.state;
        fromData.storeLogo = storeLogo;
        this.setState({ fromData }, () => {
            this.handleSeve();
        })
    }
    handleSeve = () => {
        const { fromData } = this.state;
        const { loadingBack, getData } = this.props;
        loadingBack && loadingBack(true);
        save_settings(fromData).then(res => {
            getData && getData(true);
        }).catch(err => {
            getData && getData(false);
        }).finally(e => {
            loadingBack && loadingBack(false);
            console.log('finally', e);
        })
    }
    handleRangeSliderChange = (rangeValue) => {
        const { sliderMax, fromData = {} } = this.state;
        fromData.checkoutChannelRatio = sliderMax - rangeValue;
        this.setState({ rangeValue, fromData })
    }
    onChange = (checked) => {
        const { fromData = {} } = this.state;
        fromData.promotion_is_active = checked;
        this.setState({ fromData })
    }
    render(props, state) {
        const { active, fromData, sliderMax, rangeValue } = this.state;
        let { checkoutLanguage, storeLogo, checkoutChannelRatio,promotion_is_active } = fromData;
        const options = [
            { label: 'Auto Detect Language', value: '' },
            { label: 'America English', value: 'en-US' },
            { label: 'German', value: 'de' },
            { label: 'Spanish', value: 'es' },
            { label: 'French', value: 'fr' },
        ];
        const suffixStyles = {
            minWidth: '24px',
            textAlign: 'right',
        };
        return <Layout>
            <Layout.AnnotatedSection
                title="Default store logo"
                description="Upload a default logo for your store. Checkout page will use this defalit logo when there is no logo uploaded in a funnel."
            >
                <Supload callback={this.imgcallback} file={storeLogo} />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
                title="Funnel Config"
            // description="description."
            >
                <Card sectioned title="Checkout Portion">
                    <div className={styles.sliderTitle}><span>Shopify</span><span>OnePage</span></div>
                    <RangeSlider
                        output
                        min={0}
                        max={sliderMax}
                        value={rangeValue}
                        onChange={this.handleRangeSliderChange}
                        prefix={<p className={styles.sliderFix}>{rangeValue}%</p>}
                        suffix={<p className={styles.sliderFix} style={suffixStyles}>{checkoutChannelRatio}%</p>}
                    />
                </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
                title="Checkout language"
                description="Checkout language for Intercart funnels."
            >
                <Card>
                    <div className={styles.section}>
                        <Select
                            disabled={false}
                            label="Select checkout page language"
                            options={options}
                            onChange={this.handleChange.bind(this, 'checkoutLanguage')}
                            value={checkoutLanguage}
                        />
                        {/* <Checkbox
                            disabled={true}
                            label="Auto switch to cusomer's language"
                            checked={checked}
                            
                            onChange={this.handleChange.bind(this, 'checked')}
                        /> */}
                    </div>
                </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
                title="View Promotion"
                description="Display promotion alerts."
            >
                <Card sectioned title="Display countdown">
                    <div>
                        <Switch checkedChildren="启用" unCheckedChildren="禁用" onChange={this.onChange} checked={promotion_is_active}/>
                    </div>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>
    }
}

export default Index;