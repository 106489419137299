/*
 * @Author: yangj
 * @Date: 2020-02-21 10:55:21
 */
import React, { Component } from "react";
import classNames from 'classnames';
import styles from './style.module.scss';
import { DropZone, Card, Thumbnail, DisplayText, Badge, TextStyle, Spinner } from '@shopify/polaris';
import {upload} from "../../api/api"
class Index extends Component {
    constructor() {
        super();
        this.state = {
            upLoading: false,
            files: [],
            isUrl:false
        };
    }
    componentWillReceiveProps(nextProps) {
        let {file}=nextProps||{};
        let arr=[];
        file&&arr.push(file);
        this.setState({files:arr,isUrl:!!file});
    }
    handleDrop = (files) => {
        this.setState({upLoading:true });
        const {callback}=this.props;
        upload(files[0]).then(res=>{
            let arr=[];
            res&&res.data&&arr.push(res.data);
            this.setState({ files:arr,isUrl:true,upLoading:false });
            callback&&callback(res&&res.data);
        }).catch(e=>{
            this.setState({upLoading:false });
        })
    }
    removeLogo=(e)=>{
        this.setState({ files:[],isUrl:true });
        const {callback}=this.props;
        callback&&callback();

    }
    render(props, state) {
        const { onFocus, files = [],isUrl,upLoading } = this.state;
        const uploadedFiles = (
            <div className={styles.imgBox}>
                <div>
                    {files.length>0&&files.map((file, index) => (
                        <img key={index} alt={file.name} src={isUrl?file:window.URL.createObjectURL(file)} />
                    ))}
                </div>
                <DisplayText size="small"><TextStyle variation="subdued">Cilck or drop another file to change logo.</TextStyle></DisplayText>
            </div>
        );
        return <div>
            <Card
                secondaryFooterActions={[{ content: 'Remove Logo', destructive: true,onClick:this.removeLogo }]}
            >
                <Card.Section>
                    <DropZone accept="image/*" type="image" onDrop={this.handleDrop} disabled={upLoading}>
                        {files.length > 0 ? uploadedFiles : <DropZone.FileUpload />}
                    </DropZone>
                </Card.Section>
            </Card>
        </div>
    }
}

export default Index;