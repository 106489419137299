/*
 * @Author: yangj
 * @Date: 2020-04-29 10:41:31
 * @LastEditors: yangj
 */
import React, { Component } from "react";
import { get_funnel_pay_detail } from '../../../api/api';
import { Modal } from '@shopify/polaris';
import { Table } from 'antd';
class Index extends Component {
    constructor() {
        super();
        this.state = {
            active: false,
            total: 1,
            pageSize: 20,
            current: 1,
        };
    }
    handleSeve = () => {
        //父组件的操作
    }
    getDetails = () => {
        this.setState({ loading: true });
        let { total, current, list = [],pageSize,date } = this.state;
        get_funnel_pay_detail({ date,pageSize,pageIndex:current }).then((res = {}) => {
            let { Records = [], totalRecords = 1, pageIndex = 1 } = res;
            list = Records;
            total = totalRecords;
            current = pageIndex;
        }).finally(e => {
            this.setState({ loading: false, list, total, current });
        })
    }
    showMe = (val) => {
        const { date } = val || {};
        this.setState({ date,active: true, total: 1, current: 1, list: [] },()=>{
            date && this.getDetails();
        });
    }
    handleCloes = () => {
        this.setState({ active: false,  })
    }
    changePage=(current,pageSize)=>{
        this.setState({ current },()=>{
            this.getDetails();
        })
    }
    render(props, state) {
        let { date,active = false, loading = false, list = [], total, pageSize, current } = this.state;
        console.log('current',current);
        const columns = [
            // {
            //     title: 'Currency',
            //     dataIndex: 'Currency',
            //     key: 'Currency',
            // },

            {
                title: 'Platform order id',
                dataIndex: 'PlatformOrderID',
                key: 'PlatformOrderID',
            },
            {
                title: 'Amount',
                dataIndex: 'Value',
                key: 'Value',
                render: (text, record) => (
                    <span>{record.Value > 0 ? `$${record.Value}` : 0}</span>
                )
            },
            {
                title: 'Shipping fee',
                dataIndex: 'ShippingFee',
                key: 'ShippingFee',
                render: (text, record) => (
                    <span>{record.ShippingFee > 0 ? `$${record.ShippingFee}` : 0}</span>
                )
            },
            {
                title: 'Total refund',
                dataIndex: 'TotalRefund',
                key: 'TotalRefund',
                render: (text, record) => (
                    <span>{record.TotalRefund > 0 ? `$${record.TotalRefund}` : 0}</span>
                )
            },

            {
                title: 'Financial status',
                dataIndex: 'FinancialStatus',
                key: 'FinancialStatus',
            },
            {
                title: 'Pay method',
                dataIndex: 'PayMethod',
                key: 'PayMethod',
            },
            {
                title: 'Gate way',
                dataIndex: 'GateWay',
                key: 'GateWay',
            },
        ];
        const pagination = {
            total,
            pageSize,
            current,
            onChange:this.changePage
        }
        return (
            <Modal
                open={active}
                onClose={this.handleCloes}
                title={`Detail (${date})`}
                large={true}
            >
                <Modal.Section style={{ minHeight: '500px' }}>
                    <Table pagination={pagination} loading={loading} size='small' columns={columns} dataSource={list} rowKey="PlatformOrderID" />
                </Modal.Section>
            </Modal>
        )
    }
}

export default Index;

