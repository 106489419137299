/*
 * @Author: yangj
 * @Date: 2019-12-16 09:28:17
 * @LastEditors: yangj
 */
import { request } from '../utils/request';

export function upload(data) {
  let formData = new FormData();
  formData.append("file", data);
  return request({
    url: 'api/upload',
    method: 'post',
    data:formData,
    isLoading:true
  });
}
export function save_settings(data) {
  return request({
    url: 'api/shop/save_settings',
    method: 'post',
    data,
    isLoading:true
  });
}
export function save_integrations(data) {
  return request({
    url: 'api/shop/save_integrations',
    method: 'post',
    data,
    isLoading:true
  });
}
export function save_payments(data) {
  return request({
    url: 'api/shop/save_payments',
    method: 'post',
    data,
    isLoading:true
  });
}
export function get_config(data) {
  return request({
    url: 'api/shop/get_config',
    method: 'get',
    data,
    isLoading:true
  });
}
export function get_all_country(data={}) {
  return request({
    url: 'api/shop/get_all_country',
    method: 'get',
    data
  });
}
export function get_funnel_pay_static(params={}) {
  return request({
    url: 'api/static/get_funnel_pay_static',
    method: 'get',
    params
  });
}
export function get_funnel_pay_detail(params={}) {
  return request({
    url: 'api/static/get_funnel_pay_detail',
    method: 'get',
    params
  });
}
export function get_funnel_pay_summary(params={}) {
  return request({
    url: 'api/static/get_funnel_pay_summary',
    method: 'get',
    params,
    isLoading:true
  });
}