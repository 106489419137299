/*
 * @Author: yangj
 * @Date: 2020-02-21 10:55:21
 */
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { AppProvider } from '@shopify/polaris';
import styles from './style.module.scss';
import { Spinner } from '@shopify/polaris';
class Index extends Component {
	constructor() {
		super();
		this.state = {
		};
	}
	componentDidMount() {
	}
	componentWillUnmount() {
	}
	render(props, state) {
		const { visible, meassge = 'Please wait……' } = this.props;
		return <div className={styles.spinner}>
			<Spinner accessibilityLabel="Spinner example" size="large" color="inkLightest" />
		</div>
	}
}

let dom = null;
const loading = {
	show() {
		if (dom) return
		dom = document.createElement('div');
		document.body.appendChild(dom);
		ReactDOM.render(<AppProvider i18n={{}}><Index /></AppProvider>, dom);
	},
	hide() {
		if (!dom) return
		try {
			ReactDOM.render(null, dom);
			document.body.removeChild(dom);
			dom=null
		} catch {

		}
	}
}
export default loading;