/*
 * @Author: yangj
 * @Date: 2020-02-21 10:55:21
 */
import React, { Component } from "react";
import moment from 'moment';
import styles from './style.module.scss';
import DetailModal from './DetailModal';
import { get_funnel_pay_static } from '../../../api/api';
import { Card, Button } from '@shopify/polaris';
import { DatePicker, Table } from 'antd';
const { RangePicker } = DatePicker;
class Index extends Component {
    constructor() {
        super();
        const startDate = moment().subtract(1, "M").subtract(1, "d")
        const endDate = moment().subtract(1, "d")

        this.state = {
            sliderMax: 100,
            rangeValue: 0,
            total: 1,
            fromData: {
                pageIndex: 1,
                pageSize: 20,
                startAt: startDate.format('YYYY-MM-DD'),
                endAt: endDate.format('YYYY-MM-DD')
            },
            list: [],
            loading:false
        };
    }
    componentDidMount() {
        this.getData()
    }
    getData = () => {
        let { fromData, list=[],total } = this.state;
        this.setState({ loading: true });
        get_funnel_pay_static(fromData).then((res = {}) => {
            let { Records = [], totalRecords = 1, pageIndex = 1 } = res;
            list = Records;
            total = totalRecords;
            fromData.pageIndex = pageIndex;
            this.setState({ list,fromData,total })
        }).finally(e => {
            this.setState({ loading: false});
        })
    }
    initData = () => {
        //初始化时候 父组件的操作
    }
    handleSeve = () => {
        //父组件的操作
    }
    changePicker = (e) => {
        let { fromData = {} } = this.state;
        if (e && typeof (e) === 'object') {
            fromData.startAt = moment(e[0]).format('YYYY-MM-DD');
            fromData.endAt = moment(e[1]).format('YYYY-MM-DD');
        } else {
            fromData.startAt = '';
            fromData.endAt = '';
        }
        this.setState({ fromData },()=>{
            this.getData()
        });
    }
    // handleSearch=()=>{
    //     console.log(this.state.fromData);
    // }
    handleDetails = (date) => {
        this.refs.DetailModal.showMe({ date });
        // get_funnel_pay_detail({date}).then(res=>{
        //     console.log('res',res);
        // })
    }
    changePage = (current, pageSize) => {
        let {fromData}=this.state;
        fromData.pageIndex=current;
        this.setState({ fromData }, () => {
            this.getData();
        })
    }
    render(props, state) {
        let {total=1, fromData: {pageSize=20,  pageIndex:current=1,startAt = '', endAt = '' }, list = [],loading=false } = this.state;
        const searchRender = () => {
            const dateFormat = 'YYYY-MM-DD';
            return (
                <div className={styles.search}>
                    <div className={styles.inputs}><RangePicker allowClear={false} onChange={this.changePicker} defaultValue={[moment(startAt, dateFormat), moment(endAt, dateFormat)]} /></div>
                    {/* <span>{`compared to ${moment(startAt, dateFormat).subtract(1,'d').format('YYYY-MM-DD')}--${endAt}`}</span> */}
                    {/* <Button primary loading={false} onClick={this.getData}>Search</Button> */}
                </div>)
        };
        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Total amount',
                dataIndex: 'totalPayAmount',
                key: 'totalPayAmount',
                render: (text, record) => (
                    <span>{record.totalPayAmount > 0 ? `$${record.totalPayAmount}` : 0}</span>
                )
            },
            {
                title: 'Fast paypal',
                dataIndex: 'fastPayPalAmount',
                key: 'fastPayPalAmount',
                render: (text, record) => (
                    <span>{record.fastPayPalAmount > 0 ? `$${record.fastPayPalAmount}` : 0}</span>
                )
            },
            {
                title: 'Normal paypal',
                dataIndex: 'normalPayPalAmount',
                key: 'normalPayPalAmount',
                render: (text, record) => (
                    <span>{record.normalPayPalAmount > 0 ? `$${record.normalPayPalAmount}` : 0}</span>
                )
            },
            {
                title: 'WorldPay credit',
                dataIndex: 'worldPayCreditAmount',
                key: 'worldPayCreditAmount',
                render: (text, record) => (
                    <span>{record.worldPayCreditAmount > 0 ? `$${record.worldPayCreditAmount}` : 0}</span>
                )
            },
            {
                title: 'Pacpay credit',
                dataIndex: 'pacPayCreditAmount',
                key: 'pacPayCreditAmount',
            },
            {
                title: 'Ebanx credit',
                dataIndex: 'eBanXCreditAmount',
                key: 'eBanXCreditAmount',
                render: (text, record) => (
                    <span>{record.eBanXCreditAmount > 0 ? `$${record.eBanXCreditAmount}` : 0}</span>
                )
            },
            {
                title: 'Total refund',
                dataIndex: 'totalRefundAmount',
                key: 'totalRefundAmount',
                render: (text, record) => (
                    <span>{record.totalRefundAmount > 0 ? `$${record.totalRefundAmount}` : 0}</span>
                )
            },
            {
                title: 'Channel ratio',
                dataIndex: 'channelRatio',
                key: 'channelRatio',
                render: (text, record) => (
                    <span>{record.channelRatio > 0 ? `${record.channelRatio}%` : '0%'}</span>
                )
            },
            {
                title: 'Detail',
                key: 'action',
                //   fixed: 'right',
                width: 80,
                render: (text, record) => (
                    <span>
                        <Button plain onClick={this.handleDetails.bind(this, record.date)}>view</Button>
                    </span>
                ),
            },
        ];
        const pagination = {
            total,
            pageSize,
            current,
            onChange: this.changePage
        }
        return <div className={styles.page}>
            <Card>
                {searchRender()}
                <Table loading={loading} pagination={pagination} columns={columns} dataSource={list} rowKey="date" />
            </Card>
            <DetailModal ref="DetailModal"></DetailModal>
        </div>
    }
}

export default Index;

