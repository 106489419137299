/*
 * @Author: yangj
 * @Date: 2020-02-21 10:55:21
 */
import React, { Component } from "react";
import moment from 'moment';
import styles from './style.module.scss';
import TotalPay from "./TotalPay"
import ConvertRate from "./ConvertRate"
import ViewReport from "./ViewReport"

import { get_funnel_pay_summary } from '../../../api/api';
import { Card, Button } from '@shopify/polaris';
import { DatePicker, Table } from 'antd';
const { RangePicker } = DatePicker;
class Index extends Component {
    constructor() {
        super();
        const startDate = moment().subtract(2, 'd')
        const endDate = moment().subtract(1, 'd')

        this.state = {
            sliderMax: 100,
            rangeValue: 0,
            total: 1,
            fromData: {
                startAt: startDate.format('YYYY-MM-DD'),
                endAt: endDate.format('YYYY-MM-DD')
            },
            onPage: 0,//0为主页
            funnel_pay_summary:{}
        };
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        //初始化时候 父组件的操作
        let { fromData,funnel_pay_summary={}} = this.state;
        get_funnel_pay_summary(fromData).then((res = {}) => {
            funnel_pay_summary=res||{};
        }).finally(e => {
            this.setState({ funnel_pay_summary });
        })
    }
    initData = () => {
    }
    componentWillUnmount() {
    }
    changePicker = (e) => {
        let { fromData = {} } = this.state;
        if (e && typeof (e) === 'object') {
            fromData.startAt = moment(e[0]).format('YYYY-MM-DD');
            fromData.endAt = moment(e[1]).format('YYYY-MM-DD');
        } else {
            fromData.startAt = '';
            fromData.endAt = '';
        }
        this.setState({ fromData }, () => {
            this.getData()
        });
    }
    handleSeve = () => {
        //父组件的操作
    }
    changePage = (onPage) => {
        this.setState({ onPage })
    }
    render(props, state) {
        let {fromData: {startAt = '', endAt = '' }, onPage = 0,funnel_pay_summary } = this.state;
        const searchRender = () => {
            const dateFormat = 'YYYY-MM-DD';
            const startDate = moment(startAt, dateFormat)
            const endDate = moment(endAt, dateFormat)
            const diffDays = endDate.diff(startDate, 'd') + 1
            // console.log(diffDays, startDate, endDate)
            const compareStart = moment(startAt, dateFormat).subtract(diffDays, 'd')
            const compareEnd = moment(startAt, dateFormat).subtract(1, 'd')

            
            return (
                <div className={styles.search}>
                    <div className={styles.inputs}><RangePicker allowClear={false} onChange={this.changePicker} defaultValue={[moment(startAt, dateFormat), moment(endAt, dateFormat)]} /></div>
                    <span>{`compared to ${compareStart.format('MMMM Do')} - ${compareEnd.format('MMMM Do')}`}</span>
                </div>)
        };
        return <div className={styles.box}>
            {onPage !== 0 && <Button size="slim" onClick={this.changePage.bind(this, 0)}>←</Button>}
            <div style={onPage === 0?{}:{display:'none'}}>
                {searchRender()}
                <div className={styles.cardList}>
                    <TotalPay ref='total_pay' callback={this.changePage} datas={funnel_pay_summary}/>
                    <ConvertRate ref='convert_rate' callback={this.changePage} datas={funnel_pay_summary}/>
                </div>
            </div>
            {onPage !== 0 && <ViewReport />}
        </div>
    }
}

export default Index;

