/*
 * @Author: yangj
 * @Date: 2020-02-21 10:55:21
 */
import React, { Component } from "react";
import moment from 'moment';
import styles from './style.module.scss';
// import { get_funnel_pay_static } from '../../../api/api';
import { Card, Button } from '@shopify/polaris';
import { DatePicker, Table } from 'antd';
const { RangePicker } = DatePicker;
class Index extends Component {
    constructor() {
        super();
        this.state = {
            itemList: [
                {
                    title: 'Paypal', price: 'payPal', status: 'payPalRate', child: [
                        { title: 'Express', price: 'fastPayPal', status: 'fastRate' },
                        { title: 'Normal', price: 'normalPayPal', status: 'normalRate' },
                        
                    ]
                },
                {
                    title: 'Credit', price: 'credit', status: 'creditRate', child: [
                        { title: 'WorldPay', price: 'worldPay', status: 'worldPayRate' },
                        { title: ' Pacpay', price: 'pacPay', status: 'pacPayRate' },
                        { title: ' Ebanx', price: 'eBanX', status: 'eBanXRate' },
                    ]
                }
            ]
        };
    }
    initData = () => {
        //初始化时候 父组件的操作
    }
    handleSeve = () => {
        //父组件的操作
    }
    reS = (num=0) => {
        if (num === 0) {
            return `${Math.abs(num)}%`
        }else if (num < 0) {
            return `↓${Math.abs(num)}%`
        } else {
            return `↑${Math.abs(num)}%`
        }
    }
    render(props, state) {
        let { itemList = [] } = this.state;
        let { datas = {} } = this.props;
        let { today = {}, totalRate = 0 } = datas;
        let { totalPay = 0, } = today;
        let { callback } = this.props;
        console.log('datas',datas);
        return (
            <div className={styles.cardBox}>
                <Card>
                    <div className={styles.cardBody}>
                        <div className={styles.title}>
                            <div className={styles.tl}>All channel amount</div>
                            <div><Button plain onClick={() => { callback && callback(1) }}>View report</Button></div>
                        </div>
                        <div className={styles.titleData}>
                            <h3>${totalPay}</h3>
                            <div className={styles[totalRate===0?'gray':(totalRate < 0 ? 'red' : 'green')]}>{this.reS(totalRate)}</div>
                        </div>
                        {itemList.map((item,index) => {
                            return (
                                <div className={styles.item} key={index}>
                                    <div className={styles.primary}>
                                        <div className={styles.itemTitle}>{item.title}</div>
                                        <div className={styles.itemMain}>
                                            <span>${today[item.price]||0}</span>
                                            <span>{this.reS(datas[item.status]||0)}</span>
                                        </div>
                                    </div>
                                    {item.child&&item.child.map((child,ind)=>{
                                        return <div className={styles.itemChlid} key={ind}>
                                        <div className={styles.itemTitle}>{child.title}</div>
                                        <div className={styles.itemMain}>
                                            <span>${today[child.price]||0}</span>
                                            <span>{this.reS(datas[child.status]||0)}</span>
                                        </div>
                                    </div>
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </Card>
            </div>
        )
    }
}

export default Index;

