import React, { Component } from "react";
import moment from 'moment';
import styles from './style.module.scss';
import { get_funnel_pay_static } from '../../../api/api';
import { Card, Button } from '@shopify/polaris';
import { DatePicker, Table } from 'antd';
const { RangePicker } = DatePicker;
class Index extends Component {
    constructor() {
        super();
        this.state = {
            itemList: [
                {
                  title: 'Place Orders', price: 'orderQuantity', status: 'orderQuantityRate',
                },
                {
                  title: 'Reached checkout', price: 'reachCheckoutCount', status: 'reachCheckoutCountRate',
                },
            ]
        };
    }
    initData = () => {
        //初始化时候 父组件的操作
    }
    handleSeve = () => {
        //父组件的操作
    }
    reS = (num=0) => {
        if (num === 0) {
            return `${Math.abs(num)}%`
        }else if (num < 0) {
            return `↓${Math.abs(num)}%`
        } else {
            return `↑${Math.abs(num)}%`
        }
    }
    render(props, state) {
        let {itemList = [] } = this.state;
        let { datas = {}} = this.props;
        let { callback } = this.props;
        return (
            <div className={styles.cardBox}>
                <Card>
                    <div className={styles.cardBody}>
                        <div className={styles.title}>
                            <div className={styles.tl}>Conversion rate</div>
                        </div>
                        <div className={styles.titleData}>
                            <h3>{datas.convertRate}%</h3>
                            <div className={styles[datas.convertCheckoutRate===0?'gray':(datas.convertCheckoutRate < 0 ? 'red' : 'green')]}>{this.reS(datas.convertCheckoutRate)}</div>
                        </div>
                        {itemList.map((item,index) => {
                            return (
                                <div className={styles.item} key={index}>
                                    <div className={styles.primary}>
                                        <div className={styles.itemTitle}>{item.title}</div>
                                        <div className={styles.itemMain}>
                                            <span>{datas[item.price]||0}</span>
                                            <span>{this.reS(datas[item.status]||0)}</span>
                                        </div>
                                    </div>
                                    {item.child&&item.child.map((child,ind)=>{
                                        return <div className={styles.itemChlid} key={ind}>
                                        <div className={styles.itemTitle}>{child.title}</div>
                                        <div className={styles.itemMain}>
                                            <span>{datas[child.price]||0}</span>
                                            <span>{this.reS(datas[child.status]||0)}</span>
                                        </div>
                                    </div>
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </Card>
            </div>
        )
    }
}

export default Index;

