/*
 * @Author: yangj
 * @Date: 2020-03-10 11:39:57
 * @LastEditors: yangj
 */
import axios from 'axios'
import Sloading from "../compoents/Sloading"
// import store from '@/store'

// const baseURL = process.env.NODE_ENV === 'production' ? '' : 'https://7c30d4a8.ngrok.io';

const request = axios.create({
  // baseURL,
  timeout: 30000 // request timeout
})
const DOMAIN = process.env.DOMAIN;
const baseURL=`https://${DOMAIN}/`
// request interceptor
request.interceptors.request.use(
  config => {
    // if (store.getters.token) {
    //   // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    //   config.headers['X-Token'] = getToken()
    // }
    const isDev=(process.env.NODE_ENV === 'development');
    let {url,headers,isLoading}=config||{};
    const urlParams = new URLSearchParams(window.location.search);
    const Shop = isDev?'checkoutfive.myshopify.com':urlParams.get("shop");
    const AuthCode = isDev?'109ae162a29f986f9d4ae2b026f1fa1b':urlParams.get("code");
    config.headers['Shop'] = Shop;
    config.headers['AuthCode'] = AuthCode;
    // config.headers['Content-Type'] = 'application/json';
    config.url=`${baseURL}${url}`;
    
		isLoading&&Sloading.show();
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  response =>{
    let{status,data,statusText}=response||{};
    console.log('response',response);
    Sloading.hide();
    if(status===200){
      return Promise.resolve(data);
    }else{
      alert(statusText||'server error');
      return Promise.reject(data);
    }
  },
  error => {
    Sloading.hide();
    let{data=''}=error.response||{};
    console.log('error.response',error.response);
    return Promise.reject(data)
  }
)

export  {request};