/*
 * @Author: yangj
 * @Date: 2020-02-27 10:03:21
 */
import React, { Component } from "react";
import styles from './App.module.scss';
import { Settings, Integration, Payment, Dashboard } from './compoents/app';
import { get_config } from '../src/api/api';
import { Button, Tabs, Card, Frame, Toast } from '@shopify/polaris';
class Index extends Component {
	constructor() {
		super();
		this.state = {
			selected: 0,
			loading: false,
			active: false,
			toastStatus: true,//是否保存成功
			message: '',
			datas: {},
			tabs: [{ id: 'Dashboard', content: 'Dashboard', styleTyle: 'all' }, 
			{ id: 'Integration', content: 'Integration' }, 
			// { id: 'Payment', content: 'Payment' }, 
			{ id: 'Settings', content: 'Settings' }],
		}
	}
	componentDidMount() {
		this.getConfig();
	}
	closeToast = (e) => {
		this.setState({ active: false });
	}
	handleTabChange = (selected) => {
		this.setState({ selected });
	}
	renderMain = (type) => {
		let { datas } = this.state;
		switch (type) {
			case 0:
				return <Dashboard ref='Dashboard' loadingBack={this.loadingBack} datas={datas} getData={this.getShop} />;
			case 1:
				return <Integration ref='Integration' loadingBack={this.loadingBack} datas={datas} getData={this.getShop} />;
			// case 2:
			// 	return <Payment ref='Payment' loadingBack={this.loadingBack} datas={datas} getData={this.getShop} />;
			case 2:
				return <Settings ref='Settings' loadingBack={this.loadingBack} datas={datas} getData={this.getShop} />;
			default:
				return `${type}模块开发中`;
		}
	}
	loadingBack = (loading) => {
		this.setState({ loading })
	}
	handleSeve = () => {
		const { tabs, selected } = this.state;
		this.refs[tabs[selected].id].handleSeve();
	}
	getShop = (status, message) => {
		if (status) {
			this.setState({ active: true, toastStatus: true, message });
			this.getConfig();
		} else {
			this.setState({ active: true, toastStatus: false, message });
		}
	}
	getConfig = () => {
		get_config().then(res => {
			this.setState({ datas: res || {} });
		}).catch(e => {
			this.getShop(false, e);
		}).finally(e => {
			const { tabs, selected } = this.state;
			console.log('initData', this.refs[tabs[selected].id]);
			this.refs[tabs[selected].id].initData();
			return true
		})
	}
	render() {
		const { tabs, selected, loading, active, toastStatus, message } = this.state;
		return (
			<div className={styles.box} id='_app'>
				<div className={styles.header_box}>
					<Card>
						<div className={styles.header}>
							<Tabs tabs={tabs} selected={selected} onSelect={this.handleTabChange}>
							</Tabs>
							{selected !== 0 && <div className={styles.btnParent}><Button primary loading={loading} onClick={this.handleSeve}>save</Button></div>}
						</div>
					</Card>
				</div>
				<Frame>
					<div className={styles.main}>
						{/* <Button onClick={this.test}>get</Button> */}
						<div className={styles[tabs[selected].styleTyle || 'right']}>
							{this.renderMain(selected)}
						</div>
						{active ? <Toast duration={3000} content={toastStatus ? "Saved successfully" : (message || "Save failed")} error={!toastStatus} onDismiss={this.closeToast} /> : null}
					</div>
				</Frame>
			</div>
		);
	}
}

export default Index;
