/*
 * @Author: yangj
 * @Date: 2020-02-21 10:55:21
 */
import React, { Component } from "react";
import classNames from 'classnames';
import styles from './style.module.scss';
import { Supload } from '../../index';
import { save_integrations } from '../../../api/api';
import { Layout, Card,Link,Button, Checkbox, TextField, TextStyle,} from '@shopify/polaris';
class Index extends Component {
    constructor() {
        super();
        this.state = {
			fromData: { storeFrontToken:'',googleAnaAccountId:'',facebookPixelId:''},
            disableds:{storeFrontToken:false,googleAnaAccountId:false,googleAdConversionId:false,facebookPixelId:false},
        };
    }
    componentDidMount() {
        this.initData();
    }
    initData=()=>{
        console.log('Intrgrations');
        let {datas}=this.props;
        const { fromData,disableds } = this.state;
        Object.keys(fromData).forEach(key=>{
            fromData[key]=datas[key]||'';
        });
        Object.keys(disableds).forEach(item=>{
            disableds[item]=!!fromData[item];
        })
        this.setState({fromData,disableds});
    }
    componentWillUnmount() {
    }
    handleDrop = (files) => {
        console.log('files', files);
        this.setState({ files })
    }
    removeLogo = (e) => {
        this.setState({ files: [] })
    }
	handleChange = (type, value) => {
		let { fromData } = this.state;
		fromData[type] = value;
		this.setState({ fromData });
	}
    changeDisabled=(type)=>{
        let {disableds}=this.state;
		disableds[type] = false;
        this.setState({disableds})
    }
    handleSeve=()=>{
        const { fromData} = this.state;
        const { loadingBack,getData } = this.props;
        loadingBack&&loadingBack(true);
        save_integrations(fromData).then(res=>{
            getData&&getData(true);
        }).catch(err=>{
            getData&&getData(false);
        }).finally(e=>{
            loadingBack&&loadingBack(false);
            console.log('finally',e);
        })
    }
    render(props, state) {
        const { disabled,disableds, fromData, } = this.state;
		let { storeFrontToken,googleAnaAccountId,facebookPixelId} = fromData;
        return <Layout>
            <Layout.AnnotatedSection
                title="Shopify Abandoned Checkouts"
                description="Storefront Access Token enables you to integrate Shopify abandoned checkouts third-party abandoned cart apps."
            >
                <Card>
                    <div className={styles.section}>
                        <TextField
                            disabled={disableds.storeFrontToken}
                            placeholder="Storefront Access Token"
                            label={<p>Storefront Access Token <strong>(Required)</strong></p>}
                            value={storeFrontToken}
                            onChange={this.handleChange.bind(this, 'storeFrontToken')}
                            labelAction={disableds.storeFrontToken&&{ content: 'Change',onClick:this.changeDisabled.bind(this,'storeFrontToken')}}
                            helpText={<Button plain>Setup instructions</Button>}
                        />
                    </div>
                </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
                title="Google Analytics"
                description={<p className={styles.leftText}>Google Analytics enables you to track the visitors to your store, and generates reports that will help you with your marketing. <Link url=""> Learn more about Google Analytics</Link>.</p>}
            >
                <Card>
                    <div className={styles.section}>
                        <TextField
                            disabled={disableds.googleAnaAccountId}
                            placeholder="Google Analytics Account ID"
                            label="Google Analytics Account ID"
                            value={googleAnaAccountId}
                            onChange={this.handleChange.bind(this, 'googleAnaAccountId')}
                            labelAction={disableds.googleAnaAccountId&&{ content: 'Change',onClick:this.changeDisabled.bind(this,'googleAnaAccountId')}}
                        />
                        {/* <Checkbox
                            label={<p className={styles.leftText}>Use Enhanced Ecommerce. <Link url="" onClick={(e)=>e.stopPropagation()}> Learn more</Link></p>}
                            checked={checked}
                            onChange={this.handleChange.bind(this, 'checked')}
                        /> */}
                    </div>
                </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
                title="Facebook Pixel"
                description={<p className={styles.leftText}>Facebook Pixel helps you create ad campaigns to find new customers that look most like your buyers.<Link url=""> Learn more about Facebook Pixel</Link>.</p>}
            >
                <Card>
                    <div className={styles.section}>
                        <TextField
                            disabled={disableds.facebookPixelId}
                            placeholder="Facebook Pixel ID"
                            label="Facebook Pixel ID"
                            value={facebookPixelId}
                            onChange={this.handleChange.bind(this, 'facebookPixelId')}
                            labelAction={disableds.facebookPixelId&&{ content: 'Change',onClick:this.changeDisabled.bind(this,'facebookPixelId')}}
                            helpText={<Button plain>+ Advanced settings</Button>}
                        />
                    </div>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>
    }
}

export default Index;